<template>
  <div style="width: 1200px;margin: auto">
    <navigation></navigation>
    <el-breadcrumb style="background-color: #fff;padding: 30px 0 30px 30px;border-bottom: 2px solid #2C9FE8" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><span style="color: #767676;cursor: pointer;" @click="$router.push({name: 'home'})">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="color: #00BCEA;">中心简介</span></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="center-introduction-container">
      <div class="left-nav">
        <div class="nav-item"
             :class="selectComId === item.id ? 'nav-item-active': ''"
             v-for="(item, index) in navList"
             :key="index"
             @click="selectCom(item)">
          {{ item.title }}&nbsp&nbsp&nbsp >
        </div>
      </div>
      <div class="right-content">
        <generalProfile v-if="selectComId === 'generalProfile'"></generalProfile>
        <historical v-if="selectComId === 'historical'"></historical>
        <leadership :isCheckDetail="isCheckDetail" @showDetail="showDetail" v-if="selectComId === 'leadership'"></leadership>
        <organization :isCheckDetail="isCheckDetail" @showDetail="showDetail" v-if="selectComId === 'organization'"></organization>
        <partyUnion :isCheckDetail="isCheckDetail" @showDetail="showDetail" v-if="selectComId === 'partyUnion'"></partyUnion>
      </div>
    </div>
  </div>
</template>

<script>
  import navigation from '@/components/navigation'
  import generalProfile from './generalProfile'
  import historical from './historical'
  import leadership from './leadership'
  import organization from './organization'
  import partyUnion from './partyUnion'
  export default {
    name: "index",
    components: {
      navigation,
      generalProfile,
      historical,
      leadership,
      organization,
      partyUnion
    },
    data() {
      return {
        isCheckDetail: false,
        navList: [
          {
            title: '中心概况',
            id: 'generalProfile'
          },
          {
            title: '历史沿革',
            id: 'historical'
          },
          {
            title: '现任领导',
            id: 'leadership'
          },
          {
            title: '组织结构',
            id: 'organization'
          },
          {
            title: '党建工会',
            id: 'partyUnion'
          }
        ],
        selectComId: 'generalProfile'
      }
    },
    created() {
      this.selectComId = this.$route.query.selectComId || 'generalProfile'
    },
    methods: {
      selectCom(item) {
        this.selectComId = item.id
        this.$router.push({
          name: 'centerIntroduction',
          query: {
            selectComId: item.id
          }
        })
        this.isCheckDetail = false
      },
      showDetail() {
        this.isCheckDetail = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.center-introduction-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  min-height: 800px;
  .left-nav {
    width: 190px;
    border-right: 1px solid #ddd;
    /*border-top: 1px solid #ddd;*/
    .nav-item {
      border-bottom: 1px solid #ddd;
      width: 190px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
    }
    .nav-item-active {
      color: #00BCEA;
    }
  }
  .right-content {
    flex: 1;
  }
}
</style>