<template>
  <div class="historical-container">
    <div class="title">
      {{ introduceTitle }}
    </div>
    <div class="time">
      发布时间： {{ createTime }}
    </div>
    <div id="content" class="content wangEditor-content">
    </div>
  </div>
</template>

<script>
  export default {
    name: "historical",
    data() {
      return {
        introduceTitle: '',
        createTime: ''
      }
    },
    mounted() {
      this.selectCenterIntroduce()
    },
    methods: {
      selectCenterIntroduce() {
        this.$api.home.selectCenterIntroduce({
          introduceType: '历史沿革'
        }).then(res => {
          let {code, result} = res
          if (code == 200) {
            result.rows && result.rows[0] && result.rows[0].id && this.$api.home.selectCenterIntroduceById({
              id: result.rows[0].id
            }).then(res => {
              let { introduceTitle, introduceContent, createTime } = res.result
              this.createTime = createTime
              this.introduceTitle = introduceTitle
              document.getElementById('content').innerHTML = introduceContent || ''
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .historical-container {
    padding: 20px;
    div {
      width: 100%;
      text-align: center;
    }
    .title {
      font-size: 22px;
      color: #1E1E1E;
      margin-bottom: 30px;
    }
    .time {
      font-size: 14px;
      color: #4A4A4A;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
    .content {
      /*padding: 20px;*/
      /*font-size: 18px;*/
      /*& > div {*/
        /*margin: 10px 0;*/
      /*}*/
      /*.subtitle {*/
        /*width: 100%;*/
        /*text-align: left;*/
        /*font-weight: bolder;*/
      /*}*/
      /*.desc {*/
        /*width: 100%;*/
        /*text-align: left;*/
        /*text-indent: 2em;*/
      /*}*/
    }
  }
</style>